<template>
  <div id="subscription-list">
    <v-card>
      <v-overlay :value="OverlayLoadingFlag">
        <v-progress-circular
          indeterminate
          color="#4A148C"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>
      <br /><br />
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12" align="center">
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-overlay
            v-model="LoadingFlag"
            contained
            class="align-center justify-center"
          ></v-overlay>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="12" align="center">
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              rounded
              class="font-size-h6 mr-3 my-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="6" md="3">
                <h6 class="text-dark">
                  <span class="text-danger">* </span>Year
                </h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <h6 class="text-dark">
                  <span class="text-danger">* </span>Zone
                </h6>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <h6 class="text-dark">LO(optional)</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col align="center" cols="12" md="12" style="margin-top: 30px">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="getTableRecords"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="#000FFFFF"
                  class="font-size-h6 px-4 mr-3 white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="SearchFlag && !StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container
          class="py-0"
          v-if="!SearchFlag && !StartupLoadingFlag && rows.length == 0"
        >
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row> </v-container
        ><br /><br />
        <v-container class="py-0" v-if="!SearchFlag && rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="ExcelDownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    TableData1,
                    TableOptions1.ExcelFields,
                    TableOptions1.ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                >Download</v-btn
              >
            </v-col>
            <p></p>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="TableOptions1.ItemKey"
                :single-select="TableOptions1.SingleSelectFlag"
                :headers="TableColumns1"
                :items="TableData1"
                :items-per-page="TableOptions1.ItemsPerPage"
                :footer-props="TableOptions1.FooterProps"
              >
                <template v-slot:item.Phase1Status="{ item }">
                  <br />
                  <v-chip
                    :color="getActiveStatusColor(item.Phase1Status)"
                    draggable
                    dark
                    >{{ item.Phase1Status }}</v-chip
                  >
                  <v-tooltip bottom v-if="item.Phase1CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.Phase1CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-btn
                    medium
                    v-if="item.Phase1EmailButtonFlag"
                    @click.prevent="sendCertificate(1, item)"
                    color="pink"
                    elevation="30"
                    rounded
                    class="font-size-h6 mr-3 my-3 white--text"
                    >Send Certificate
                  </v-btn>
                </template>
                <template v-slot:item.Phase2Status="{ item }">
                  <br />
                  <v-chip
                    :color="getActiveStatusColor(item.Phase2Status)"
                    draggable
                    dark
                    >{{ item.Phase2Status }}</v-chip
                  >
                  <v-tooltip bottom v-if="item.Phase2CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.Phase2CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-btn
                    medium
                    v-if="item.Phase2EmailButtonFlag"
                    @click.prevent="sendCertificate(2, item)"
                    color="pink"
                    elevation="30"
                    rounded
                    class="font-size-h6 mr-3 my-3 white--text"
                    >Send Certificate
                  </v-btn>
                </template>
                <template v-slot:item.Phase3Status="{ item }">
                  <br />
                  <v-chip
                    :color="getActiveStatusColor(item.Phase3Status)"
                    draggable
                    dark
                    >{{ item.Phase3Status }}</v-chip
                  >
                  <v-tooltip bottom v-if="item.Phase3CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.Phase3CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-btn
                    medium
                    v-if="item.Phase3EmailButtonFlag"
                    @click.prevent="sendCertificate(3, item)"
                    color="pink"
                    elevation="30"
                    rounded
                    class="font-size-h6 mr-3 my-3 white--text"
                    >Send Certificate
                  </v-btn>
                </template>
                <template v-slot:item.Phase4Status="{ item }">
                  <br />
                  <v-chip
                    :color="getActiveStatusColor(item.Phase4Status)"
                    draggable
                    dark
                    >{{ item.Phase4Status }}</v-chip
                  >
                  <v-tooltip bottom v-if="item.Phase4CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.Phase4CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-btn
                    medium
                    v-if="item.Phase4EmailButtonFlag"
                    @click.prevent="sendCertificate(4, item)"
                    color="pink"
                    elevation="30"
                    rounded
                    class="font-size-h6 mr-3 my-3 white--text"
                    >Send Certificate
                  </v-btn>
                </template>
                <template v-slot:item.Phase5Status="{ item }">
                  <br />
                  <v-chip
                    :color="getActiveStatusColor(item.Phase5Status)"
                    draggable
                    dark
                    >{{ item.Phase5Status }}</v-chip
                  >
                  <v-tooltip bottom v-if="item.Phase5CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.Phase5CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-btn
                    medium
                    v-if="item.Phase5EmailButtonFlag"
                    @click.prevent="sendCertificate(5, item)"
                    color="pink"
                    elevation="30"
                    rounded
                    class="font-size-h6 mr-3 my-3 white--text"
                    >Send Certificate
                  </v-btn>
                </template>
                <template v-slot:item.Phase6Status="{ item }">
                  <br />
                  <v-chip
                    :color="getActiveStatusColor(item.Phase6Status)"
                    draggable
                    dark
                    >{{ item.Phase6Status }}</v-chip
                  >
                  <v-tooltip bottom v-if="item.Phase6CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.Phase6CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-btn
                    medium
                    v-if="item.Phase6EmailButtonFlag"
                    @click.prevent="sendCertificate(6, item)"
                    color="pink"
                    elevation="30"
                    rounded
                    class="font-size-h6 mr-3 my-3 white--text"
                    >Send Certificate
                  </v-btn>
                  <br />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

    <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
export default {
  mixins: [common],
  components: {},
  data() {
    return {
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      OverlayLoadingFlag: false,
      ResultFlag: false,
      TableSelectFlag: false,
      ExcelDownloadFlag: false,
      ExcelFileName: "",
      TableOptions1: [],
      TableData1: [],

      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      EditRowData: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        // this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        // this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.StartupLoadingFlag = false;
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "mission_one_lakh",
        Action: "report_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getJciYearCodeOptions();
      this.getZoneCodeOptions();
      this.getLomCodeOptions();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    editAlert(tr) {
      console.log("editAlert called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        // var CityId = tr.CityId;
        // this.member = tr;
        this.EditRowData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 5,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var YearCode = this.JciYearCode;
      console.log("YearCode=" + YearCode);

      this.rows = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/mol/consolidate-report";
      var upload = {
        UserInterface: 1,
        Year: this.JciYearCode,
        Zone: this.ZoneCode,
        Lom: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.rows = [];
      this.SearchFlag = true;
      this.LoadingFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;
      this.ExcelDownloadFlag = false;
      this.ExcelFileName = "";
      this.TableData1 = [];

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          thisIns.StartupLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          records = response.data.records;
          console.log(
            "output=" + output + ", flag=" + flag + ", records=" + records
          );
          var options = records.TableOptions;
          thisIns.TableOptions1 = options;
          if (flag == 1) {
            thisIns.TableColumns1 = records.TableHeader;
            thisIns.rows = records.TableData;
            thisIns.TableData1 = records.TableData;
            thisIns.TableSelectFlag = records.TableSelectFlag;
            thisIns.ExcelDownloadFlag = options.DownloadFlag;
            thisIns.ExcelFileName = options.ExcelFileName;
            thisIns.ExcelFields = options.ExcelFields;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.toast("error", error);
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
        });
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    sendCertificate(type, tr) {
      console.log("send_certificate is called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      console.log({ type });
      if (n1 > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/mol/send-certificate";
        var YearCode = this.JciYearCode;
        console.log({ YearCode });
        var upload = {
          UserInterface: 1,
          PhaseType: type,
          MissionOneLakh: tr.MOLId,
          Year: this.JciYearCode,
          LO: tr.LOMId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        this.LoadingFlag = true;
        this.OverlayLoadingFlag = true;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
    <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
}
</style>